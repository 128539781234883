import React from 'react'
import Lottie from 'react-lottie'

import { IProps } from './interfaces'

const LottieAnimation: React.FC<IProps> = ({ animationData, height, width, ...lottieOptions }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...lottieOptions,
  }

  return <Lottie options={defaultOptions} height={height} width={width} />
}

export default LottieAnimation
