import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Button } from '../../../Components'
import { ROUTE_NAMES } from '../../../Layouts/Unauthorized/interfaces'
import { routes } from '../../../Layouts/Unauthorized/routes'
import Styles from '../styles.module.scss'
import { parseUrl } from './helpers'

export const SessionExpiredPage: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onLogoutBtnClick = (): void => {
    const url = parseUrl()
    if (url) window.location.href = url

    navigate(routes[ROUTE_NAMES.LOGIN])
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer} />
      <h1>{t('Your session has expired.')}</h1>
      <p className={Styles.errorMessage}>
        {t(
          'Your session has expired and you have been logged out. To continue using the platform, you will need to login again.'
        )}
      </p>

      <Button id="logout-button" variant="primary" onClick={onLogoutBtnClick}>
        {t('Back to login')}
      </Button>
    </div>
  )
}

export default SessionExpiredPage
