import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { MainLoading } from '../../Components/MainLoading'
import { MaintenanceNotice } from '../../Components/MaintenanceNotice'
import { useGetThemeLazyQuery } from '../../Lib/graphql'
import { getSSOConfig } from '../../Lib/jwtHelper'
import { setCSSColorVarsForColors } from './helpers'
import { IThemeContext, TTheme } from './interfaces'

export const ThemeContext = React.createContext<IThemeContext>({
  isDarkSidebar: false,
  currentTheme: {},
  setOverrideHostname: (): void => {},
})

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element | null => {
  const isDriven = window.location.hostname.includes('driven')
  const [isDarkSidebar] = useState<boolean>(!isDriven)
  const [loadThemeForHost, { data, loading, error }] = useGetThemeLazyQuery()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [theme, setTheme] = useState<TTheme>({})
  const [overrideHostname, setOverrideHostname] = useState<string>()

  const ssoConfig = getSSOConfig()

  const getHostName = useCallback((): string => {
    if (overrideHostname) return overrideHostname
    if (ssoConfig?.host) return ssoConfig.host
    return window.location.hostname
  }, [overrideHostname, ssoConfig?.host])

  const updateFavicon = useCallback((uri: string): void => {
    const links = document.querySelectorAll("link[rel~='icon']")

    if (!links.length) {
      const link = document.createElement('link')

      link.rel = 'icon'
      link.href = uri

      document.head.appendChild(link)
    } else {
      links.forEach((link) => {
        const htmlLink = link as HTMLLinkElement

        htmlLink.href = uri
      })
    }
  }, [])

  useEffect(() => {
    loadThemeForHost({
      variables: {
        hostname: getHostName(),
      },
    })
  }, [getHostName, loadThemeForHost, overrideHostname])

  // Set initial fallback
  useEffect(() => {
    setCSSColorVarsForColors({ primaryColor: theme?.primaryColor, secondaryColor: theme?.secondaryColor })

    if (theme.favicon?.uri) updateFavicon(theme.favicon?.uri)
  }, [theme, updateFavicon])

  // This useEffect hook below processes the CSS when we received a response from the backend
  useEffect(() => {
    if (!data) return
    if (!initialized) setInitialized(true)
    if (!data?.configurationProfileForHost?.customerLabelTheme) return

    setTheme(data.configurationProfileForHost.customerLabelTheme)
  }, [data, initialized])

  useEffect(() => {
    let timeout: number

    if (error) {
      timeout = window.setTimeout((): void => {
        window.location.reload()
      }, 10000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [error])

  const options = useMemo(() => {
    return { isDarkSidebar, currentTheme: theme || {}, overrideHostname, setOverrideHostname }
  }, [isDarkSidebar, overrideHostname, theme])

  if (error) return <MaintenanceNotice error={error} />
  if (!initialized || loading) return <MainLoading />
  return <ThemeContext.Provider value={options}>{children}</ThemeContext.Provider>
}
