import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IResult, IValidationError } from '../../Lib/graphql'
import { IErrors, IUseMutationHelperHook, TOptions } from './interfaces'

export const useMutationHelper = (): IUseMutationHelperHook => {
  const { t } = useTranslation()

  const [attributeErrors, setAttributeErrors] = useState<IErrors>({})
  // Errors that are not linked to a form element.
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const toastify = (result: IResult | undefined, options?: TOptions): void => {
    if (result?.success) {
      toast.success(result.success, {
        autoClose: 8000,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-left',
      })
      if (options?.onSuccess) options.onSuccess()
      return
    }

    const baseError = result?.error || t('The submit contained errors, please try again...')
    const validationErrors = options?.validationErrors?.map((error) => error.fullMessages).flat() || []
    validationErrors.unshift(baseError)

    const errorMessage = validationErrors
      .map((error) => {
        if (error.trim().slice(-1) !== '.') return `${error}.`
        return error
      })
      .join(' ')

    toast.error(errorMessage, {
      autoClose: false,
      position: 'bottom-left',
    })
    if (options?.onError) options.onError()
  }

  const handleErrorMessages = (validationErrors: IValidationError[]): string[] => {
    const attributes = {} as IErrors
    const messages: string[] = []

    validationErrors.forEach((error): void => {
      if (error.attribute === 'base') error.fullMessages.forEach((msg) => messages.push(msg))
      attributes[error.attribute] = error.fullMessages
    })

    setErrorMessages(messages)
    setAttributeErrors(attributes)
    return messages
  }

  return { handleErrorMessages, errorMessages, attributeErrors, toastify }
}
