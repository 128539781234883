/**
 * This helper will try to parse a url passed through the
 * query string. If the url is not available as a query string it will return null.
 * On succes: the url including https:// prefix is returned
 * On failure: null is returned
 */
export const parseUrl = (): string | null => {
  const queryParams = location.search

  if (queryParams) {
    const params = new URLSearchParams(queryParams)
    const url = params.get('url')

    if (url) return `https://${url}`
  }

  return null
}
