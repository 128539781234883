import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useResponsiveDimensionOfRef } from '../../Hooks/useResponsiveDimensionOfRef'
import { MediaQueriesContext } from '../../Providers'
import { BreadcrumbsContext } from '../../Providers/BreadcrumbsProvider'
import { ContentHeader } from '../ContentHeader'
import { SidePanel } from '../SidePanel'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PageContainer: React.FC<IProps> = ({
  title,
  subTitle,
  topContent,
  footerContent,
  sidePanelContent,
  pageContentWrapperClassName,
  pageContentClassName,
  postTitleContent,
  postSubTitleContent,
  contentToFullHeight,
  children,
}): JSX.Element | null => {
  const footerRef = useRef<HTMLDivElement>(null)
  const { isSMScreen } = useContext(MediaQueriesContext)
  const { path: breadcrumbs } = useContext(BreadcrumbsContext)
  const footerDimension = useResponsiveDimensionOfRef(footerRef)
  const { t } = useTranslation()

  const pageContainerStyle = ((): React.CSSProperties => {
    const style: React.CSSProperties = {}

    if (footerContent) style.marginBottom = `${footerDimension.height - 1}px`

    return style
  })()

  return (
    <div
      className={`${Styles.page} ${sidePanelContent ? Styles.hasSidePanel : null}`}
      aria-label={t('Page container.')}
    >
      <div className={`${Styles.pageContentWrapper} ${pageContentWrapperClassName || ''}`}>
        {(isSMScreen || breadcrumbs.length <= 1) && (
          <ContentHeader
            title={title}
            subTitle={subTitle}
            postSubTitleContent={postSubTitleContent}
            postTitleContent={postTitleContent}
          />
        )}

        {topContent}

        <div
          id="pageContent"
          className={`${Styles.pageContent} ${pageContentClassName || ''} ${
            contentToFullHeight ? Styles.fullHeight : ''
          }`}
          style={pageContainerStyle}
        >
          {children}
        </div>

        {footerContent && (
          <div className={Styles.footerContent} data-testid="footerContent" ref={footerRef}>
            {footerContent}
          </div>
        )}
      </div>

      {sidePanelContent && (
        <div className={Styles.sidePanelContainer} data-testid="sidePanelContainer">
          <SidePanel>{sidePanelContent}</SidePanel>
        </div>
      )}
    </div>
  )
}
